import React, { useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link as ScrollLink } from 'react-scroll'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const navItems = [
    { id: 1, path: 'home', label: 'Home' },
    { id: 2, path: 'posts', label: 'Posts' },
    { id: 3, path: 'followme', label: 'Followme' },
    { id: 4, path: 'work', label: 'Work' },
    { id: 5, path: 'skills', label: 'Skills' },
    { id: 6, path: 'contact', label: 'Contact' }
  ]

  const handleNav = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      {/* Navbar fixed with h-16 across all devices */}
      <header className='fixed w-full bg-cyan-900 text-gray-300 h-16 flex justify-between items-center px-4 z-50'>
        <h1 className='uppercase font-bold text-lg md:text-xl lg:text-2xl truncate mx-16'>
          Albin<span className='text-cyan-550'>&nbsp;Issac</span>
        </h1>

        <ul className='hidden md:flex'>
          {navItems.map(item => (
            <li
              key={item.id}
              className='p-2 hover:bg-[#00df9a] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'
            >
              <ScrollLink
                to={item.path}
                smooth={true}
                duration={500}
                onClick={() => setIsMenuOpen(false)}
                href={`#${item.path}`}
              >
                {item.label}
              </ScrollLink>
            </li>
          ))}
        </ul>

        <div onClick={handleNav} className='md:hidden z-10' data-testid='toggle-menu'>
          {isMenuOpen ? (
            <AiOutlineClose size={20} data-testid='close-icon' />
          ) : (
            <AiOutlineMenu size={20} data-testid='hamburger-icon' />
          )}
        </div>
      </header>

      {/* Mobile Menu */}
      <ul
        className={`fixed text-gray-300 inset-x-0 top-16 bottom-0 bg-cyan-900 overflow-y-auto z-40 transition-all duration-300 ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } md:hidden`}
      >
        {navItems.map(item => (
          <li
            key={item.id}
            className='text-white p-4 border-b border-gray-600 hover:bg-[#00df9a] rounded-xl duration-300 hover:text-black w-full text-center'
          >
            <ScrollLink
              to={item.path}
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
              href={`#${item.path}`}
              data-testid={`mobile-menu-${item.label}`}
            >
              {item.label}
            </ScrollLink>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Navbar
