import React, { Suspense, useEffect, useState } from 'react'
// Lazy load the components
import Navbar from './components/Navbar'
const Contact = React.lazy(() => import('./components/Contact'))
const Home = React.lazy(() => import('./components/Home'))
const Skills = React.lazy(() => import('./components/Skills'))
const Works = React.lazy(() => import('./components/Works'))
const Footer = React.lazy(() => import('./components/Footer'))
// Import the local JSON data
import localData from './data/myprofile.json'

function App() {
  const [data, setData] = useState([])

  useEffect(() => {
    // No need to make an axios call, just use the imported data
    setData(localData.portfolio)
  }, [])

  return (
    <div>
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Home content={data[0]} />
        <Works content={data[1]} />
        <Skills content={data[2]} />
        <Contact />
        <Footer />
      </Suspense>
    </div>
  )
}
export default App
